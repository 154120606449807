import Button from 'components/Button'
import { navigate } from 'gatsby'
import { useCards } from 'hooks/useCards'
import AppLayout from 'layouts/AppLayout'
import { BASE_URL } from 'gatsby-env-variables'

import CheckCircle from '../../../../static/images/icons/check-circle.svg'

import './CardConclusion.scss'

const CardConclusion = () => {
  const { selectedCardInfo } = useCards()

  if (!selectedCardInfo) return null

  return (
    <AppLayout useContentHeader={false} contentClassName="card-conclusion">
      <CheckCircle />
      <p className="card-conclusion__description">
        Você receberá o status do seu pedido no seu e-mail. Enquanto isso,{' '}
        <strong>abra sua conta digital totalmente grátis!</strong>
      </p>
      <Button
        className="card-conclusion__button"
        onClick={() => navigate('/abrir-conta')}
        tracking={{
          htmlId: 'a0521904-5f37-4c4d-a287-f43725419635',
          location: 'cards-feedback',
          name: 'click-form-cards-feedback-crossell-account',
          actionOutcome: 'crossell-account',
          outboundUrl: `${BASE_URL}/abrir-conta`,
        }}
      >
        Abrir conta
      </Button>

      <div className="card-conclusion__resume">
        <img
          src={selectedCardInfo.image}
          alt={selectedCardInfo.title}
          className="card-conclusion__card-image"
        />

        <div className="card-conclusion__card-info">
          <h3 className="card-conclusion__card-title">Resumo do Pedido</h3>
          <p className="card-conclusion__card-name">{selectedCardInfo.title}</p>
        </div>
      </div>

      <div className="stepper">
        <div className="stepper__step">
          <div className="stepper__badge stepper__badge--completed" />
          <p className="stepper__description">Início da proposta</p>
        </div>
        <div className="stepper__step">
          <div className="stepper__badge stepper__badge--completed" />
          <p className="stepper__description">Preenchimento de dados</p>
        </div>
        <div className="stepper__step">
          <div className="stepper__badge" />
          <p className="stepper__description">Análise das informações</p>
        </div>
        <div className="stepper__step">
          <div className="stepper__badge" />
          <p className="stepper__description">Envio do cartão</p>
        </div>
      </div>
    </AppLayout>
  )
}

export default CardConclusion
